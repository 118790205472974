import React from 'react'
import DownloadableProducts from '../components/ProductList/DownloadableProducts'

function DownloadableProductsPage() {
  return (
    <div>
      <DownloadableProducts />
    </div>
  )
}

export default DownloadableProductsPage
