import React from 'react';

const ImageModal = ({ imageUrl, closeModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-md">
        <img src={imageUrl} alt="Full View" className="max-w-full max-h-full" />
        <button className='bg-[#ff0000] text-[16px] px-2 py-1 font-semibold text-white rounded' onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export default ImageModal;
