import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

function HeartBtn({ type, _id, reply, likes, likeCommentHandler, user, addingCommentLike}) {
    const [isLiked, setIsLiked] = useState(likes?.includes(user?.u_id) || false)
    const [likesLength , setLikesLength] = useState(8)
    useEffect(() => {
        setIsLiked(likes?.includes(user?.u_id)  || false)
        setLikesLength(likes?.length || 0)
    }, [likes, user])
    const likesCountStatus = () => {
        if(isLiked) {
            setLikesLength(likesLength - 1)
        }
        else {
            setLikesLength(likesLength + 1)
        }
    }
    const likeHandler = () => {
        if(type == "Comment") {
            likeCommentHandler(_id)
        } else {
            likeCommentHandler(reply._id, _id)
        }
    }
    return (
        <button disabled={addingCommentLike} onClick={() => {likeHandler();likesCountStatus(!isLiked);setIsLiked(!isLiked)}}><FontAwesomeIcon icon={faHeart} color={`${isLiked ? '#000' : 'red'}`} /><span>{likesLength}</span></button>
    )
}

export default HeartBtn
