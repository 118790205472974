import React from 'react'
import WishList from '../components/ProductList/WishList'

const WishlistPage = () => {
  return (
    <>
      <WishList />
    </>
  )
}

export default WishlistPage
