import React, { useEffect, useState } from 'react'
import DescriptionColumn from './DescriptionColumn';
import { useGetproductQuery, useUpdateModelInfoMutation } from '../../redux/apiCalls/apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { getToken } from '../../Token/token';
import { toast } from 'react-toastify';
function UpdateModel() {
    const { productIdForUpdate } = useParams()
    const token = getToken()
    const { data: productDetails, isLoading, error, refetch } = useGetproductQuery(productIdForUpdate)
    const [details, setDetails] = useState({
        title: productDetails?.product?.title || "",
        description: productDetails?.product?.description || "",
        category: productDetails?.product?.category || "",
        modalSetting: productDetails?.product?.modalSetting || "",
        license: productDetails?.product?.license || "Kalyke - Private Use (by default)",
        price: productDetails?.product?.price || "0",
        tags: productDetails?.product?.tags || [],
        youtubeURL: productDetails?.product?.youtubeURL
    });
    useEffect(() => {
        setDetails({
            title: productDetails?.product?.title,
            description: productDetails?.product?.description,
            category: productDetails?.product?.category,
            modalSetting: productDetails?.product?.modalSetting,
            license: productDetails?.product?.license,
            price: productDetails?.product?.price,
            tags: productDetails?.product?.tags,
            youtubeURL: productDetails?.product?.youtubeURL
        })
    }, [productIdForUpdate])
    const [updateModelInfo] = useUpdateModelInfoMutation()
    const navigate = useNavigate()
    const productUploadHandler = async () => {
        try {
            const res = await updateModelInfo({ details, token, productIdForUpdate })
            console.log(res, 'res')
            toast.success("Model Published.");
            navigate("/")
        } catch (err) {
            toast.error(err)
        }
    };
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = 'Do you want to close? Changes you made may not be saved.';
            event.returnValue = message;
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    if (error) return <div className='flex items-center justify-center h-[100vh] w-full'><span>Opps, Something went wrong!</span></div>
    if (isLoading) return <div className='flex items-center justify-center h-[100vh] w-full'><ClimbingBoxLoader color={"#000"} size={20} aria-label="Loading Spinner" data-testid="loader" /></div>
    return (
        <div className='pb-[361px]  p-4 max-w-[1500px] mx-auto'>
            <h2 className='text-[30px] font-semibold'>Update your design</h2>
            <div className=' gap-10 flex h-[100vh]'>
                <DescriptionColumn details={details} setDetails={setDetails} />
                <div>
                    <div>
                        <span className="font-medium text-[18px] mt-7 block">Youtube Video URL</span>
                        <input value={productDetails?.product?.youtubeURL} onChange={(e) => setDetails({ ...details, youtubeURL: e.target.value })} type="text" placeholder='YT URL' className='w-full px-2 h-[40px] border-[2px] border-[#c1b9b9] rounded-sm' />
                    </div>
                    <button onClick={productUploadHandler} className="h-[46px] bg-blue-500 mt-3 text-white px-4 py-2 rounded hover:bg-blue-600">Upload</button>
                </div>
            </div>
        </div>
    )
}

export default UpdateModel
