import React from 'react'
import ProductDetails from '../components/ProductDetails/ProductDetails'

function ProductDetailsPage() {
  return (
    <>
      <ProductDetails />
    </>
  )
}

export default ProductDetailsPage
