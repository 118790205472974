import React from 'react';


import { toast } from 'react-toastify';

import DropZone from '../Common/DropZone';
import ImagesList from '../Common/ImagesList';

const ImagesUploader = ({ fileUploadProgress, isUploadLoading, selectedFile, setSelectedFile }) => {

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (selectedFile.length + files.length > 6) {
      return toast.error("Maximum 8 images could be uploaded at a time");
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/jpg' || file.type === 'image/gif') {
        setSelectedFile((prevFiles) => [...prevFiles, file]);
      } else {
        toast.error("Only jpeg, png, webp, gif, jpg are supported.");
      }
    }
  };
  
  const removeImageHandler = (id) => {
    const imageExist = selectedFile.filter((item, index) => index !== id)
    setSelectedFile(imageExist)
  }

  return (
    <>
      <span className='font-medium text-[18px] block mt-8'>Upload Images for model</span>
      <DropZone isUploadLoading={isUploadLoading} handleFileChange={handleFileChange} />
      {selectedFile.length > 0 ? <ImagesList fileUploadProgress={fileUploadProgress} removeImageHandler={removeImageHandler} selectedFile={selectedFile} /> : "No files uploaded"}
    </>
  );
};

export default ImagesUploader;