import React, { useState } from 'react';
import { useAddReplyMutation } from '../../redux/apiCalls/apiSlice';
import { getToken } from '../../Token/token';
import { toast } from 'react-toastify';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../config/config';
import DropZone from '../Common/DropZone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ImagesList from '../Common/ImagesList';

const ReplyComment = ({ productId, commentId, onReplyAdded }) => {
  const token = getToken();
  const [reply, setReply] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('')
  const [selectedFile, setSelectedFile] = useState([])
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [addReply, { isLoading: addingReply }] = useAddReplyMutation();

  const addReplyHandler = async () => {
    if (reply.length <= 0) return toast.error("Please add your reply");
    try {
      const uploadPromises = selectedFile.map(async (file) => {
        const imageRef = ref(storage, `replies/${productId}/reply-${Math.random()}-${Date.now()}-${Math.random()}/${file.name}`);
        try {
          const snapshot = await uploadBytes(imageRef, file);
          const url = await getDownloadURL(snapshot.ref);
          return { downloadLink: url, refernceLink: imageRef.toString() };
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      });
      const uploadedFiles = await Promise.all(uploadPromises);
      const updatedReply = {
        text: reply,
        images: uploadedFiles,
        youtubeURL
      }
      const response = await addReply({ productId, token, commentId, reply: updatedReply });
      if (response && response.error) {
        toast.error(response.error?.data?.message || "Failed to add your reply, please try again.");
      } else {
        onReplyAdded();
      }
      setReply('');
      setYoutubeURL('')
      setSelectedFile([])
    } catch (error) {
      toast.error(error.message || "Something went wrong!");
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (selectedFile.length + files.length > 6) {
      return toast.error("Maximum 5 images could be uploaded");
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/jpg' || file.type === 'image/gif') {
        setSelectedFile((prevFiles) => [...prevFiles, file]);
      } else {
        toast.error("Only jpeg, png, webp, gif, jpg are supported.");
      }
    }
  };

  const removeReplyImageHandler = (id) => {
    const updatedSelectedImages = selectedFile.filter((item, index) => index != id)
    setSelectedFile(updatedSelectedImages)
  }

  const closeUploadModal = () => {
    setUploadModalOpen(false);
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };


  return (
    <div className="ml-8 mt-2">
      <textarea
        onChange={(e) => setReply(e.target.value)}
        value={reply}
        placeholder="Add a reply..."
        className="w-full border rounded-t-md p-2 focus:outline-none focus:border-blue-500"
      />
      <input onChange={(e) => setYoutubeURL(e.target.value)} type="text" placeholder='Add your youtube video URL' className="w-full border rounded-b-md p-2 focus:outline-none focus:border-blue-500"/>
      <button onClick={openUploadModal}><FontAwesomeIcon icon={faUpload} /></button>
      <div className="mt-2">
        <button  onClick={addReplyHandler} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          {addingReply ? "Processing" : "Post Reply"}
        </button>
        {isUploadModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-md">
            <p>Upload model images</p>
              <div className='my-3'>
                <DropZone handleFileChange={handleFileChange} />
              </div>
              <div className='py-2'>
              <ImagesList fileUploadProgress={false} removeImageHandler={removeReplyImageHandler} selectedFile={selectedFile} />
              </div>
              <button className='bg-[#ff0000] text-[16px] px-2 py-1 font-semibold text-white rounded' onClick={closeUploadModal}>Close/Done</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReplyComment;
