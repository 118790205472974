import { faFacebook, faInstagram, faPinterest, faReddit, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
export const socialMedia = [
    {
        icon: faTwitter,
        link: 'https://twitter.com/home'
    },
    {
        icon: faInstagram,
        link: 'https://www.instagram.com/'
    },
    {
        icon: faReddit,
        link: 'https://www.reddit.com/r/3dprinter/submit'
    },
    {
        icon: faPinterest,
        link: 'https://www.pinterest.com/pin-creation-tool/'
    },
    {
        icon: faTiktok,
        link: 'https://www.tiktok.com/explore'
    },
    {
        icon: faFacebook,
        link: 'https://www.facebook.com/campaign/landing.php?campaign_id=1653377901&extra_1=s%7Cc%7C358050429110%7Ce%7Cfacebook%27%7C&placement=&creative=358050429110&keyword=facebook%27&partner_id=googlesem&extra_2=campaignid%3D1653377901%26adgroupid%3D65139789042%26matchtype%3De%26network%3Dg%26source%3Dnotmobile%26search_or_content%3Ds%26device%3Dc%26devicemodel%3D%26adposition%3D%26target%3D%26targetid%3Dkwd-362360550869%26loc_physical_ms%3D1011080%26loc_interest_ms%3D%26feeditemid%3D%26param1%3D%26param2%3D&gclid=CjwKCAiAjfyqBhAsEiwA-UdzJPZwL1HyVoSc9CN-x56x5LRHHze1M8qZ8NBaq5nDVHABp1y45lcjUhoC1GEQAvD_BwE'
    }
]