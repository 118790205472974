import React, { useEffect, useState } from 'react'
import { useAddCommentsMutation, useDeleteCommentMutation, useDeleteReplyMutation, useGetMyProfileQuery, useGetUserProfileQuery, useLikeCommentMutation, useLikeReplyMutation } from '../../redux/apiCalls/apiSlice'
import { getToken } from '../../Token/token'
import { toast } from 'react-toastify'
import ReplyComment from './ReplyComment'
import { Link } from 'react-router-dom'
import { faCaretRight, faHeart, faThumbsUp, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeartBtn from './HeartBtn'
import DropZone from '../Common/DropZone'
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../config/config'
import ImageModal from './ImageModal'
import ImagesList from '../Common/ImagesList'
import YouTube from 'react-youtube'

const Comments = ({ productDetails }) => {
  const token = getToken()
  const { data: myProfileData } = useGetMyProfileQuery(token)
  const [comment, setComment] = useState({
    text: '',
    youtubeURL: ''
  })
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState([])
  const openUploadModal = () => {
    setUploadModalOpen(true);
  };
  const removeCommentImageHandler = (id) => {
    const updatedSelectedImages = selectedFile.filter((item, index) => index != id)
    setSelectedFile(updatedSelectedImages)
  }

  const closeUploadModal = () => {
    setUploadModalOpen(false);
  };

  const [addComments, { isLoading: isAddingComment }] = useAddCommentsMutation()
  const [deleteComment, { isLoading: isCommentDeleting }] = useDeleteCommentMutation()
  const [deleteReply, { isLoading: isReplyDeleting }] = useDeleteReplyMutation();
  const [likeComment, { isLoading: addingCommentLike }] = useLikeCommentMutation()
  const [likeReply] = useLikeReplyMutation()
  const [replyTo, setReplyTo] = useState(null);

  const likeCommentHandler = async (commentId) => {
    try {
      await likeComment({ commentId, token, productId: productDetails?.product._id })
    } catch (error) {
      console.log(error)
    }
  }

  const likeReplyHandler = async (replyId, commentId) => {
    try {
      await likeReply({ replyId, commentId, token, productId: productDetails?.product._id })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteCommentHandler = async (comment) => {
    try {
      if (comment.images?.length > 0) {
        const deleteImagesPromises = comment.images.map(async (imagePath) => {
          const imageRef = ref(storage, imagePath.downloadLink);
          await deleteObject(imageRef);
        });
        await Promise.all(deleteImagesPromises);
      }
      const response = await deleteComment({ productId: productDetails?.product._id, token, comment_id: comment._id })
      if (response && response.error) {
        return toast.error(response.error.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const addCommentsHandler = async () => {
    if (comment.text.length <= 0) return toast.error("Please add your comment")
    try {
      const uploadPromises = selectedFile.map(async (file) => {
        const imageRef = ref(storage, `comments/${productDetails?.product._id}/comment-${Math.random()}-${Date.now()}-${Math.random()}/${file.name}`);
        try {
          const snapshot = await uploadBytes(imageRef, file);
          const url = await getDownloadURL(snapshot.ref);
          return { downloadLink: url, refernceLink: imageRef.toString() };
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      });
      const uploadedFiles = await Promise.all(uploadPromises);
      const updatedComment = {
        ...comment,
        images: uploadedFiles,
      }
      const response = await addComments({ productId: productDetails?.product._id, token, comment: updatedComment })

      if (response && response.error) {
        toast.error(response.error?.data?.message || "Failed to add your comment, please try again.")
      }
      setSelectedFile([])
      setComment({
        text: '',
        youtubeURL: ''
      })
    } catch (error) {
      toast.error(error.message || "Something went wrong!")
    }
  }

  const commentHandler = (e) => {
    setComment({ ...comment, text: e.target.value })
  }

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');

    return `${day}-${month}-${year}`;
  }

  const deleteReplyHandler = async (data) => {
    const { productId, token, comment_id, reply } = data
    try {
      if (reply.images?.length > 0) {
        const deleteImagesPromises = reply.images.map(async (imagePath) => {
          const imageRef = ref(storage, imagePath.downloadLink);
          await deleteObject(imageRef);
        });
        await Promise.all(deleteImagesPromises);
      }
      const response = await deleteReply({
        productId,
        token,
        comment_id,
        replyId: reply._id
      });

      if (response.error) {
        toast.error(response.error.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (selectedFile.length + files.length > 6) {
      return toast.error("Maximum 5 images could be uploaded");
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/jpg' || file.type === 'image/gif') {
        setSelectedFile((prevFiles) => [...prevFiles, file]);
      } else {
        toast.error("Only jpeg, png, webp, gif, jpg are supported.");
      }
    }
  };

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const opts = {
    height: '240',
    width: '240',
    playerVars: {
      autoplay: 0,
    },
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const replyHandler = (commentId) => {
    setReplyTo(commentId);
  };

  const getYoutubeVideoId = (url) => {
    if (url == null) {
      return
    }
    const match = url.match(/[?&]v=([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  return (
    <div className='py-8'>
      <span className='flex pb-3 font-semibold'>Comments</span>
      <div className='pb-2'>
        <div className="flex items-center mb-4 mt-4">
          <div className='flex flex-col w-full items-start'>
            <textarea onChange={commentHandler} value={comment.text} placeholder="Add a comment..." className="w-full border rounded-t-md p-2 focus:outline-none focus:border-blue-500"></textarea>
            <input onChange={(e) => setComment({ ...comment, youtubeURL: e.target.value })} className="w-full border rounded-b-md p-2 focus:outline-none focus:border-blue-500" type="text" placeholder='Add your youtube video Link' />
            <button onClick={openUploadModal}><FontAwesomeIcon icon={faUpload} /></button>
          </div>
          {isUploadModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-md">
                <p>Upload model images</p>
                <div className='my-3'>
                  <DropZone handleFileChange={handleFileChange} />
                </div>
                <div className='py-2'>
                  <ImagesList fileUploadProgress={false} removeImageHandler={removeCommentImageHandler} selectedFile={selectedFile} />
                </div>
                <button className='bg-[#ff0000] text-[16px] px-2 py-1 font-semibold text-white rounded' onClick={closeUploadModal}>Close/Done</button>
              </div>
            </div>
          )}
          <button onClick={addCommentsHandler} disabled={isAddingComment} className="ml-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">{isAddingComment ? "Processing" : "Post"}</button>
        </div>
      </div>
      <ul>
        {productDetails.product.comments?.map((item, index) => {
          return <li className='mb-5 pb-1' key={`Comment ${index} ${item._id}`}>
            <div className="bg-white p-2 rounded-lg shadow-md">
              <div className="flex items-start mb-2">
                <img src={item.profilePic} alt="User Avatar" className="w-8 h-8 rounded-full mr-2" />
                <div className="flex-1">
                  <div className="flex items-center mb-1">
                    <div className='flex gap-1 items-end'>
                      <Link to={`user/${item.u_id}`} className="font-bold text-gray-800">{item.userName}</Link> <span className='text-[12px]'>{formatDate(item.createdAt)}</span>
                    </div>
                    <div className="ml-auto flex gap-3 items-center">
                      <button className="text-gray-500 hover:text-blue-500" onClick={() => replyHandler(item._id)}>Reply</button>
                      {item.user == myProfileData?.myProfile?.email &&
                        <button className="text-red-500 hover:text-red-700" disabled={isCommentDeleting} onClick={() => deleteCommentHandler(item)}>Delete</button>
                      }
                      <HeartBtn type="Comment" addingCommentLike={addingCommentLike} _id={item._id} likes={item?.likes} myProfileData={myProfileData} user={myProfileData?.myProfile} likeCommentHandler={likeCommentHandler} />
                    </div>
                  </div>
                  <div>
                    <p className="text-gray-600">{item.text}</p>
                    {item.images?.length > 0 &&
                      <div className='flex gap-2 w-[100px]'>
                        {item?.images.map((item, imageIndex) => {
                          return <img key={`comment-images-${Math.random()}-${item._id}`} src={item.downloadLink} alt={`comment-image-${imageIndex}`}
                            className="cursor-pointer"
                            onClick={() => openImageModal(item.downloadLink)} />
                        })}
                      </div>
                    }
                    {item.youtubeURL &&
                      <div className='mt-3'>
                        <YouTube
                          videoId={getYoutubeVideoId(item.youtubeURL)}
                          opts={opts}
                          onReady={(event) => {
                            event.target.pauseVideo();
                          }}
                        />
                      </div>
                    }
                    {isImageModalOpen && (
                      <ImageModal imageUrl={selectedImage} closeModal={closeImageModal} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {replyTo === item._id && (
              <ReplyComment
                productId={productDetails?.product._id}
                commentId={item._id}
                onReplyAdded={() => setReplyTo(null)}
              />
            )}
            {item.replies?.map((reply, replyIndex) => (
              <div key={`Reply ${replyIndex} ${reply._id}`} className="bg-white p-2 mt-5 mb-7 rounded-lg shadow-md ml-16">
                <div className="flex mb-2">
                  <img src={reply.profilePic} alt="User Avatar" className="w-8 h-8 rounded-full mr-2" />
                  <div className="flex-1">
                    <div className="flex items-center mb-1">
                      <div className='flex gap-1 items-end'>
                        <Link to={`user/${item.u_id}`} className="font-bold text-gray-800">{reply.userName}</Link>  <span className='text-[12px]'>{formatDate(item.createdAt)}</span>
                      </div>
                      <div className="ml-auto flex gap-2 items-center">
                        <button className="text-gray-500 hover:text-blue-500" onClick={() => replyHandler(reply._id)}>Reply</button>
                        {reply.user == myProfileData?.myProfile?.email &&
                          <button className="text-red-500 hover:text-red-700" disabled={isReplyDeleting} onClick={() => deleteReplyHandler({ productId: productDetails?.product._id, token, comment_id: item._id, reply })}>Delete</button>
                        }
                        <HeartBtn addingCommentLike={addingCommentLike} reply={reply} _id={item._id} likes={reply?.likes} myProfileData={myProfileData} user={myProfileData?.myProfile} likeCommentHandler={likeReplyHandler} />
                      </div>
                    </div>
                    <p className="text-gray-600">{reply.text}</p>
                    {item.images?.length > 0 &&
                      <div className='flex gap-2 w-[100px]'>
                        {reply?.images.map((item, imageIndex) => {
                          return <img
                            key={`reply-images-${Math.random()}-${item._id}`}
                            src={item.downloadLink} alt={`comment-image-${imageIndex}`}
                            className="cursor-pointer"
                            onClick={() => openImageModal(item.downloadLink)} />
                        })}
                      </div>
                    }
                    {reply.youtubeURL &&
                      <div className='mt-3'>
                        <YouTube
                          videoId={getYoutubeVideoId(reply.youtubeURL)}
                          opts={opts}
                          onReady={(event) => {
                            event.target.pauseVideo();
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
                {replyTo === reply._id && (
                  <ReplyComment
                    productId={productDetails?.product._id}
                    commentId={item._id}
                    onReplyAdded={() => setReplyTo(null)}
                  />
                )}
              </div>
            ))}
          </li>
        })}
      </ul>
    </div>
  )
}

export default Comments
